@font-face {
	font-family: 'Conv_Carton_Six';
	src: url('./fonts/Carton_Six.eot');
	src: local('☺'), url('./fonts/Carton_Six.woff') format('woff'), url('./fonts/Carton_Six.ttf') format('truetype'), url('./fonts/Carton_Six.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

.modal-content {
	position: absolute;
	top: 40px;
	left: 15%;
	width: 70%;
	border-radius: 0px;
	background: black;
	border: none;
	z-index: 999;
}

.edge-modal {
	position: absolute;
	top: 40px;
	left: 30%;
	right: 30%;
	height: 270px;    /*width: 520px;*/
	border-radius: 0px;
	background: white;
	border: 1px solid #ddd;
	border-radius: 15px;
	z-index: 999;
}

.login-modal {
	position: absolute;
	top: 40px;
	left: 30%;
	right: 30%;
	height: 270px;    /*width: 520px;*/
	border-radius: 0px;
	background: white;
	border: 1px solid #ddd;
	border-radius: 15px;
	z-index: 999;
}

.email-modal {
	position: absolute;
	top: 40px;
	left: 30%;
	right: 30%;
	height: 200px;    /*width: 500px;
	    height: 200px;*/
	border-radius: 0px;
	background: white;
	border: 1px solid #ddd;
	border-radius: 15px;
	z-index: 999;
}

.blck-modal {
	position: absolute;
	top: 40px;
	left: 5%;
	right: 5%;
	height: 500px;
	border-radius: 0px;
	background: white;
	border: 1px solid #ddd;
	border-radius: 15px;
	z-index: 999;
}

.edge-modal-text {
	margin-top: 5%;
	margin-bottom: 7.5%;
	font-family: 'Muli', sans-serif;
	color: #666;
	font-size: 16px;
	text-align: left;
	margin-left: auto;
	margin-right: 10px;
}

#browser-img {
	text-align: right;
}

#edge-text-row {
	display: flex;
	align-items: center;
}

.modal-text {
	margin-top: 12.5%;
	margin-bottom: 7.5%;
	font-family: 'Nunito', sans-serif;
	color: #666;
	font-size: 16px;
	text-align: left;
}

.modal-btn {
	text-align: center;
	color: white;
	background: rgb(21, 160, 133);
}

.modalbtnpush{
	
	margin:10px 0 0 15px;
}

#edge-btn {
	position: relative;
	bottom: 15px;
}

#edge-logo {
	margin-top: 1%;
}

.modal-text-col {
	text-align: center;
}

.btn-col {
	text-align: center;
}

.list-number {
	font-family: Conv_Carton_Six;
	font-size: 36px;
	color: #00b3af;
}

.blck-modal-text {
	font-family: 'Nunito', sans-serif;
	color: #666;
	font-size: 16px;
	text-align: left;
	line-height: 125%;
}

.instruction-div {
	margin-top: 2%;
	margin-left: 5%;
	margin-right: 4%;
	margin-bottom: 2%;
}

.blck-help-img {
	margin-left: 5px;
	margin-right: 5px;
}

.blck-instruction-title {
	font-family: Conv_Carton_Six;
	font-size: 36px;
	color: #00b3af;
	text-align: center;
	margin-top: 2%;
}

@media only screen and (max-width:1199px) {
	.modal-content {
		left: 12.5%;
		width: 75%;
	}

	.email-modal {
		left: 25%;
		right: 25%;
	}

	.edge-modal {
		left: 25%;
		right: 25%;
	}

}

@media only screen and (max-width:991px) {
	.modal-content {
		left: 10%;
		width: 80%;
		top: 20px;
	}

	.email-modal {
		left: 22.5%;
		right: 22.5%;
		height: 180px;
	}

	.edge-modal {
		left: 20%;
		right: 20%;
		height: 285px;
	}

	#edge-btn {
		position: relative;
		bottom: 5px;
	}

}

@media only screen and (max-width:767px) {
	.modal-content {
		left: 5%;
		width: 90%;
	}

	.email-modal {
		left: 20%;
		right: 20%;
	}

	.edge-modal {
		left: 15%;
		right: 15%;
		height: 300px;
	}

	#edge-btn {
		position: static;
	}

}

@media only screen and (max-width:599px) {
	.email-modal {
		left: 10%;
		right: 10%;
	}

	.edge-modal {
		left: 7%;
		right: 7%;
		height: 310px;
	}

}

@media only screen and (max-width:399px) {
	.email-modal {
		left: 5%;
		right: 5%;
	}

	.edge-modal {
		left: 3%;
		right: 3%;
		height: 310px;
	}

}

@media only screen and (max-width:349px) {
	.edge-modal {
		left: 3%;
		right: 3%;
		height: 330px;
	}

}